import { FormGroup, Typography } from "@mui/material";
import React from "react";

const TextField = ({ formik, name, label, ...props }) => {
  return (
    <FormGroup sx={{ marginBottom: "1.58rem" }} id="contactusform">
      <Typography
        sx={{ color: "#465152", marginBottom: "0.4rem", fontSize: "1rem" }}
      >
        {label}
      </Typography>
      <input
        className="input-textfield"
        value={props.value}
        {...props}
        style={{
          height: "3rem",
          borderRadius: "1.1rem",
          padding: "1rem",
          width: "100%",
          fontSize: "0.88rem",
          border: "0.9px solid #DFDFDF",
          color: "#6A6A65",
        }}
      />
      {/* <ErrorMessage
        component="div"
        name={props.name}
        className="error-message"
      /> */}
      <Typography variant="errorText">
        {formik?.errors[name] && formik?.touched[name] && formik?.errors[name]}
      </Typography>
    </FormGroup>
  );
};

export default TextField;
