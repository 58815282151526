import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Box, Typography } from "@mui/material";

function Phone({ formik, value, name, onChange }) {
  // const [value, setValue] = useState();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        // marginTop: 4,
      }}
    >
      <PhoneInput
        country={"us"}
        value={value}
        name={name}
        placeholder="Enter mobile number"
        onChange={onChange}
        id="mobile"
        inputStyle={{
          width: "100%",
          height: "3rem",
          fontSize: "0.88rem",
          borderRadius: "1.1rem",
          border: "0.9px solid #DFDFDF",
          color: "#6A6A65",
          paddingLeft: "5rem",
        }}
        buttonStyle={{
          borderRadius: "1.1rem 0 0 1.1rem",
          padding: "0 0.8rem",
          backgroundColor: "#fff",
        }}
        containerStyle={{
          width: "100%",
        }}
      />
      <Typography variant="errorText">
        {formik?.errors[name] && formik?.touched[name] && formik?.errors[name]}
      </Typography>
    </Box>
  );
}

export default Phone;
