import { Box, Button, colors, Typography } from "@mui/material";
import React from "react";
import Modal from "@mui/material/Modal";
import cancelicon from "../assets/logo/icons/cancelicon.png";

const style = {
  background: "#fff",
  padding: { xs: "1rem", sm: "1rem", md: "2rem" },
  display: "flex",
  flexDirection: "column",
  borderRadius: "1.5rem",
  overflowY: "scroll",
};

const BasicModal = ({ isOpen, modelData, setOpen }) => {
  const handleClose = () => setOpen(false);
  return (
    <Modal
      open={isOpen}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        height: "100vh",
      }}
    >
      <Box
        sx={style}
        className="modalScroll"
        margin={{ lg: "3rem" }}
        // height={"fit-content"}
        maxHeight={"90vh"}
        width={{ xs: "90%", sm: "90%", md: "auto" }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            id="modal-modal-title"
            fontSize={{ xs: "1.5rem", md: "2rem" }}
            color="#529077"
          >
            {modelData?.prd_name}
          </Typography>
          <Box
            sx={{ width: "2rem", height: "2rem", cursor: "pointer" }}
            onClick={handleClose}
          >
            <img src={cancelicon} width="100%" height="100%" />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "7rem", md: "10rem" },
            height: { xs: "7rem", md: "10rem" },
            alignSelf: "center",
          }}
        >
          <img src={modelData?.prd_img} width="100%" height="100%" />
        </Box>

        <table
          style={{
            border: "0.75px solid #9B9B9A",
            boxShadow: "0px 1.498px 2.996px 0px rgba(0, 0, 0, 0.12)",
            borderRadius: "1rem",
            overflow: "hidden",
          }}
        >
          <tbody>
            {modelData?.prd_desc?.map((descriptiondata, i) => (
              <tr
                style={{
                  backgroundColor: i % 2 ? "#FFF0E0" : "#FFF",
                }}
              >
                <td
                  style={{
                    paddingtop: "0.7rem",
                    // paddingBottom: "0.5rem",
                    paddingLeft: "1.5rem",
                    borderRight: "1px solid #9B9B9A",
                    display: "flex",
                    alignItems: "center",
                    gap: "0.7rem",
                    borderCollapse: "collapse",
                  }}
                >
                  <Box sx={{ width: "1rem" }}>
                    <img
                      src={descriptiondata.iconLabel}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "0.75rem",
                      color: "#232325",
                      fontWeight: 500,
                      fontFamily: "Inter,sans-serif",
                      textAlign: "center",
                      paddingRight: { xs: "2.5rem", sm: "2.5rem", md: "5rem" },
                    }}
                  >
                    {descriptiondata.label}
                  </Typography>
                </td>

                <td>
                  <Typography
                    sx={{
                      fontSize: "0.75rem",
                      color: "#232325",
                      fontWeight: 500,
                      fontFamily: "Inter,sans-serif",
                      textAlign: "left",
                      paddingX: "1.5rem",
                    }}
                  >
                    {descriptiondata.value}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Modal>
  );
};

export default BasicModal;
