import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import jignesh from "../assets/images/jignesh.webp";
import jagjit from "../assets/images/jagjit.webp";
import lasantha from "../assets/images/lasantha.webp";
import chris from "../assets/images/chris.webp";
import sandip from "../assets/images/sandip.webp";
import roy from "../assets/images/roy.webp";
import linkedin from "../assets/images/Vector.png";

const teams = [
  {
    id: 1,
    img: jignesh,
    name: "Jignesh Parikh",
    desig: "Co-founder & CEO,",
    desig2: "Board Member",
    linkedinURL: "https://www.linkedin.com/in/jigneshparikh/",
    alt: "jignesh",
  },
  {
    id: 2,
    img: jagjit,
    name: "Dr. Jagjit Nanda",
    desig: "Co-founder & Chief Scientific Officer, Board Member",
    // desig2: "Board Member",
    linkedinURL: "https://www.linkedin.com/in/jagjit-nanda-06abb76/",
    alt: "jagjit",
  },
  {
    id: 3,
    img: lasantha,
    name: "Dr. Lasantha Korala",
    desig: "Chief Technology Officer",
    linkedinURL: "https://www.linkedin.com/in/lasantha-korala/",
    alt: "lasantha",
  },
  {
    id: 4,
    img: chris,
    name: "Chris Kompella",
    desig: "VP, Battery Engineering",
    linkedinURL: "https://www.linkedin.com/in/chriskompella/",
    alt: "chris",
  },

  {
    id: 5,
    img: sandip,
    name: "Sandiip Bhammer",
    desig: "Investor, Board Member",
    linkedinURL: "https://www.linkedin.com/in/sandiip-bhammer-94953614/",
    alt: "sandip",
  },
  {
    id: 6,
    img: roy,
    name: "Roy Salamé",
    desig: "Investor, Board Member",
    linkedinURL: "https://www.linkedin.com/in/royjsalam%C3%A9/",
    alt: "roy",
  },
];

const OurTeam = () => {
  return (
    <Box
      id="teams"
      my={2}
      sx={{
        m: { lg: "0rem 3rem", xs: "0rem .5rem", md: "1rem" },
        p: {
          lg: "7.2rem 3rem 0rem 3rem",
          xs: "7.8rem .5rem 0rem .5rem",
          md: "1rem",
        },
      }}
    >
      <Typography
        variant={"maintitle"}
        sx={{
          fontSize: { xs: "2rem", sm: "2rem", md: "2.2rem" },
          marginBottom: { xs: "3rem", sm: "3rem", md: "3.5rem" },
        }}
      >
        Team
      </Typography>
      <Grid container spacing={4} justifyContent={"center"}>
        {teams.map((team) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            lg={3}
            mt={"4rem"}
            // display={"flex"}
            // justifyContent={"center"}
            // alignItems={"center"}
          >
            <Box
              sx={{
                borderRadius: "25px",
                background: "#fff",
                boxShadow: "0px 4px 41px 0px rgba(0, 0, 0, 0.07);",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: {
                  xs: "1.8rem 1rem 1rem 1rem",
                  md: "3rem .5rem 1rem .5rem",
                  sm: "3rem 1.5rem 1rem 1.5rem",
                },
                // paddingX: "1.5rem",
                height: "100%",
                maxHeight: "25rem",
              }}
            >
              <Typography
                gutterBottom
                variant={"textblack"}
                component="div"
                sx={{
                  marginTop: "2rem",
                  color: "#529077",
                  fontSize: { xs: "0.9rem", sm: "0.9rem", md: "1.35rem" },
                }}
              >
                {team.name}
              </Typography>
              <Typography
                variant={"description"}
                sx={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: { xs: "0.8rem", sm: "0.9rem", md: "0.9rem" },
                  flex: 1,
                }}
              >
                {team.desig}
                <br />
                {team?.desig2}
              </Typography>
              <a href={team?.linkedinURL} target="_blank">
                <Box
                  sx={{
                    width: { xs: "2rem", sm: "2.5rem", md: "2.5rem" },
                    height: { xs: "2rem", sm: "2.5rem", md: "2.5rem" },
                    margin: { xs: ".3rem", sm: ".5rem", md: ".5rem" },
                  }}
                >
                  <img
                    src={linkedin}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Box>
              </a>

              <Box
                sx={{
                  position: "absolute",
                  margin: "auto",
                  width: { xs: "50%", sm: "42%", md: "48%", xl: "42%" },
                  top: { xs: "-33px", sm: "-70px", md: "-55px", xl: "-60px" },
                  right: 0,
                  left: 0,
                  display: "flex",
                }}
              >
                <img src={team.img} style={{ width: "100%", height: "100%" }} />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default OurTeam;
