import React from "react";
import { Box, Typography } from "@mui/material";
import banner from "../assets/video/herovideo.mp4";

const HeroBanner = () => {
  return (
    <Box
      sx={{
        height: { xs: "20.2rem", sm: "32.2rem", md: "38.5rem" },
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.69) 0%, rgba(0, 0, 0, 0.69) 100%)",
          width: "100%",
          height: "100%",
        }}
      ></Box>
      <video
        src={banner}
        width="100%"
        height="100%"
        autoPlay
        muted
        loop
        playsInline
        style={{
          objectFit: "fill",
        }}
      ></video>

      {/* <Toolbar /> */}

      <Typography
        sx={{
          fontFamily: "Inter, sans-serif",
          fontSize: { xs: "1.5rem", sm: "1.3rem", md: "2.4rem" },
          color: "#fff",
          textTransform: "uppercase",
          width: { xs: "90%", md: "100%" },
          textAlign: { xs: "start", sm: "center", md: "center" },
          position: "absolute",
          // top: { xs: "5.5rem", md: "16rem" },
          // left: { xs: "1.3rem", md: "7.3rem" },
          // right: 0,
        }}
      >
        <u>Shipping</u> world's highest energy and performance <br />
        18650 and 21700 Silicon-Graphite lithium-ion cells
      </Typography>
    </Box>
  );
};

export default HeroBanner;
