import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Footer from "./components/Footer";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import muiTheme from "./theme/theme";
import emailjs from "@emailjs/browser";
import Scrolltotop from "./components/Scrolltotop";

function App() {
  emailjs.init({
    publicKey: "YTZqsXlqqQR3Y41x2",
    // Do not allow headless browsers
    blockHeadless: true,
    limitRate: {
      // Set the limit rate for the application
      id: "app",
      // Allow 1 request per 10s
      throttle: 10000,
    },
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <>
        <Navbar />
        <Home />
        <Scrolltotop />
        <Footer />
      </>
    </ThemeProvider>
  );
}

export default App;
