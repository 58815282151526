import React from "react";
import { Box, Grid, ListItem } from "@mui/material";

import Typography from "@mui/material/Typography";
import Navbar from "./Navbar";
import "../App.css";
import HeroBanner from "../routes/HeroBanner";
import AboutUs from "../routes/AboutUs";
import OurProducts from "../routes/OurProducts";
import KeyDifferentiators from "../routes/KeyDifferentiators";
import Partners from "../routes/Partners";
import PressRelease from "../routes/PressRelease";
import OurTeam from "../routes/OurTeam";
import ContactUs from "../routes/ContactUs";

const Home = () => {
  return (
    <>
      <HeroBanner />
      <AboutUs />
      <OurProducts />
      <KeyDifferentiators />
      <Partners />
      <OurTeam />
      <PressRelease />
      <ContactUs />
    </>
  );
};

export default Home;
