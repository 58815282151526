import { KeyboardArrowUp } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useState } from "react";

const Scrolltotop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <Button
      sx={{
        position: "fixed",
        width: { xs: "2.5rem", md: "3.5rem" },
        minWidth: 0,
        minHeight: 0,
        right: { xs: ".5rem", md: "2.5rem" },
        bottom: { xs: "2rem", md: "8rem" },
        height: { xs: "2.5rem", md: "3.5rem" },
        fontSize: "3.5rem",
        zIndex: 1,
        cursor: "pointer",
        background: "#F9A13C",
        borderRadius: "1.7rem",
        color: "#fff",
        display: visible ? "flex" : "none",
      }}
    >
      <KeyboardArrowUp
        onClick={scrollToTop}
        // sx={{ display: visible ? "inline" : "none" }}
      />
    </Button>
  );
};

export default Scrolltotop;
