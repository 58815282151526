import React, { useEffect, useRef } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import TextField from "../components/TextField";
import TextArea from "../components/TextArea";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useState } from "react";
import mapimg from "../assets/images/gmap.png";
import Phone from "../components/Phonenumber";
import emailjs from "@emailjs/browser";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);

  const sendEmail = (val) => {
    setLoading(true);
    emailjs.send("service_m4311zu", "template_i8frpnf", val).then(
      () => {
        console.log("SUCCESS!");
        Swal.fire({
          title: "Sent",
          text: "Your response have been saved successfully",
          icon: "success",
        });
        setLoading(false);
      },
      (error) => {
        console.log("FAILED...", error);
        Swal.fire({
          title: "Oops",
          text: "Failed",
          icon: "error",
        });
        setLoading(false);
      }
    );
  };

  const validationSchema = Yup.object({
    fName: Yup.string()
      .required("First Name is required")
      .matches(/^[^\s].*$/, "Blank spaces at the start are not allowed"),
    lName: Yup.string()
      .required("Last Name is required")
      .matches(/^[^\s].*$/, "Blank spaces at the start are not allowed"),
    email: Yup.string().email().required("Email Name is required"),
    mobile: Yup.number(),
    message: Yup.string()
      .required("Message is required")
      .matches(/^[^\s].*$/, "Blank spaces at the start are not allowed"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      fName: "",
      lName: "",
      email: "",
      mobile: "",
      message: "",
    },
    onSubmit: (values) => {
      console.log(values, "sfds");
      sendEmail(values);
    },
  });

  const [muiPhone, setMuiPhone] = useState("+1");

  useEffect(() => {
    console.log(formik, "fname&lname");
  }, [formik]);
  return (
    <Box
      id="contactus"
      sx={{
        m: { lg: "0rem 3rem", xs: "0rem .5rem", md: "1rem" },
        p: {
          lg: "7.2rem 3rem 0rem 3rem",
          xs: "7.8rem .5rem 0rem .5rem",
          md: "1rem",
        },
      }}
    >
      <Typography
        variant={"maintitle"}
        sx={{
          fontSize: { xs: "2rem", md: "2.2rem" },
          marginBottom: { xs: "0rem", md: "2.5rem" },
        }}
      >
        CONTACT US
      </Typography>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          display={"flex"}
          flexDirection={"column"}
          my={3}
          // justifyContent={"start"}
          // alignItems={"start"}
          // borderRight={"solid #D9D9D9 3px"}
          sx={{
            borderRight: { xs: "none", md: "solid #D9D9D9 1.5px" },
            borderBottom: { xs: "solid #D9D9D9 3px", md: "none" },
          }}
        >
          <Box sx={{ padding: { xs: "0rem .5rem", md: "0 2rem 0 0" } }}>
            <img src={mapimg} width={"100%"} height={"100%"} />
          </Box>
          <Box display={"flex"} flexDirection={"column"} my={4}>
            <Typography
              fontSize={{ xs: "1rem", sm: "1rem", md: "1.2rem" }}
              sx={{ color: "#529077", textTransform: "uppercase" }}
            >
              Email
            </Typography>
            <Typography
              variant={"description"}
              fontSize={{ xs: "1rem", sm: "1rem", md: "1.2rem" }}
            >
              info@rincell.com{" "}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container mt={3}>
            {/* firstname */}
            <Grid
              item
              xs={12}
              md={6}
              display={"flex"}
              flexDirection={"column"}
              padding={{ xs: "0 1rem", md: "0 1.8rem" }}
              // m={3}
              // justifyContent={"end"}
              // alignItems={"end"}
              // paddingLeft={"30px"}
            >
              <TextField
                label="First Name *"
                placeholder="Enter First Name"
                name="fName"
                type="text"
                id="fName"
                value={formik.values.fName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched}
                formik={formik}
              />
            </Grid>
            {/* lastname */}
            <Grid
              item
              xs={12}
              md={6}
              display={"flex"}
              flexDirection={"column"}
              padding={{ xs: "0 1rem", md: "0 1.8rem" }}
              // my={3}
              justifyContent={"space-around"}
            >
              <TextField
                label="Last Name *"
                placeholder="Enter Last Name"
                name="lName"
                type="text"
                id="lName"
                value={formik.values.lName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched}
                formik={formik}
              />
            </Grid>
          </Grid>
          {/* Email */}
          <Box padding={{ xs: "0 1rem", md: "0 1.8rem" }}>
            <TextField
              label="Email *"
              placeholder="Enter email here..."
              name="email"
              type="text"
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched}
              formik={formik}
            />
          </Box>
          {/* Mobile number */}
          <Box
            sx={{
              marginBottom: "2.3rem",
              width: "100%",
              padding: { xs: "0 1rem", md: "0 1.8rem" },
            }}
          >
            <Typography
              sx={{ color: "#6A6A65", marginBottom: "4px", fontSize: "1rem" }}
            >
              Mobile Number
            </Typography>
            <Phone
              value={formik.values.mobile}
              onChange={(val) => formik.setFieldValue("mobile", val)}
              name="mobile"
              id="mobile"
              onBlur={formik.handleBlur}
              touched={formik.touched}
              formik={formik}
            />
          </Box>
          {/* message */}
          <Box padding={{ xs: "0 1rem", md: "0 1.8rem" }}>
            <TextArea
              label="Message *"
              placeholder="Enter your message here..."
              name="message"
              type="text"
              id="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched}
              formik={formik}
            />
          </Box>
          {/* button */}
          <Box
            padding={{ xs: "0 1rem 1.5rem 1rem", md: "0 1.8rem 1.5rem 1.8rem" }}
          >
            <Button
              size="large"
              disabled={loading}
              onClick={() => formik.handleSubmit()}
              sx={{
                fontFamily: "Inter,sans-serif",
                fontSize: { xs: "1rem", md: "1.4rem" },
                color: "#fff",
                textAlign: "center",
                background: "#529077",
                textTransform: "capitalize",
                borderRadius: "13px",
                width: { xs: "8rem", md: "10rem" },
                "&:hover": { backgroundColor: "#529070" },
              }}
            >
              {loading ? "Sending..." : "Submit"}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUs;
