import React from "react";
import {
  Box,
  Grid,
  ListItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import aboutimg from "../assets/images/Rectangle30.png";

const AboutUs = () => {
  return (
    <Box
      id="aboutus"
      my={2}
      display="flex"
      sx={{
        m: { lg: "0rem 3rem", xs: "0rem .5rem", md: "1rem" },
        p: {
          lg: "7.2rem 3rem 0rem 3rem",
          xs: "6.1rem .5rem 0rem .5rem",
          md: "1rem",
        },
      }}
    >
      <Grid container spacing={8}>
        <Grid item xs={12} sm={5} md={5} order={{ xs: 2, sm: 1 }}>
          <Box sx={{ height: { xs: "26rem", sm: "26rem", md: "35rem" } }}>
            <img
              src={aboutimg}
              style={{
                borderRadius: "36px",
                backgroundPosition: "50% 50%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                height: "100%",
                width: "100%",
                // objectFit: "contain",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={7} md={7} order={{ xs: 1, sm: 2 }}>
          <Stack spacing={2} justifyContent={"center"} height={"100%"}>
            <Typography
              variant={"title"}
              sx={{ fontSize: { xs: "2rem", sm: "2rem", md: "2.2rem" } }}
            >
              About Us
            </Typography>
            <Typography
              variant={"aboutusdes"}
              sx={{
                fontFamily: "Inter, sans-serif",
                fontSize: { xs: "1rem", sm: "1rem", md: "1.2rem" },
              }}
            >
              Rincell Corporation was founded to accelerate the electrification
              of high-impact carbon emission products, fostering a sustainable
              future with a secure supply chain.    
            </Typography>
            <Typography
              variant={"aboutusdes"}
              sx={{
                fontFamily: "Inter, sans-serif",
                fontSize: { xs: "1rem", sm: "1rem", md: "1.2rem" },
              }}
            >
              Rincell is committed to Domestic & Ally manufacturing of its
              high-capacity and performance Silicon-Graphite, Lithium Manganese
              Iron Phosphate (LMFP), & Sodium-ion batteries for government and
              commercial applications.
            </Typography>
            <Typography
              variant={"aboutusdes"}
              sx={{
                fontFamily: "Inter, sans-serif",
                fontSize: { xs: "1rem", sm: "1rem", md: "1.2rem" },
              }}
            >
              Rincell is on track to begin volume production of 18650 and 21700
              cell formats in Asia starting late 2024. Rincell is also building
              a megawatt-hour cell manufacturing facility in California
              scheduled to begin operations in late 2025.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;
