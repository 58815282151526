import React from "react";
import { Box, Grid, ListItem, Typography } from "@mui/material";
import logowhite from "../assets/logo/image19.png";

const Footer = () => {
  return (
    <Box
      display="flex"
      sx={{
        background: "#000",
      }}
    >
      <Box
        display="flex"
        paddingX={"3.5rem"}
        paddingY={"2.5rem"}
        width={"100%"}
      >
        <Grid container display={"flex"}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: { xs: "15rem", md: "30%" },
                marginLeft: { xs: "1rem", md: "3rem" },
              }}
            >
              <img src={logowhite} width={"100%"} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                fontFamily: "Inter,sans-serif",
                fontSize: { xs: "0.8rem", md: "1.3rem" },
                color: "#fff",
                textAlign: "center",
                justifyContent: { md: "flex-end" },
                display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                height: "100%",
                width: "100%",
                m: { xs: "1rem", md: "0rem" },
              }}
            >
              Copyright © Rincell Corporation. 2024
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;
