import { Margin } from "@mui/icons-material";
import { createTheme } from "@mui/material";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#529077",
    },
  },

  typography: {
    fontFamily: "Inter, sans-serif",
    allVariants: {
      fontFamily: "Inter,sans-serif",
      fontSize: "22px",
      color: "#000",
    },
    title: {
      fontFamily: "Inter,sans-serif",
      color: "#000",
      textTransform: "uppercase",
      fontWeight: "400",
      lineHeight: "60px",
    },
    aboutusdes: {
      fontFamily: "Inter,sans-serif",
      fontSize: "1.2rem",
      color: "#86909C",
      fontWeight: "400",
      lineHeight: "35px",
      textAlign: "start",
    },
    textblack: {
      fontFamily: "Inter, sans-serif",

      // color: "#529077",
      color: "#000",
      // textTransform: "uppercase",
      fontWeight: "400",
    },
    description: {
      fontFamily: "Inter,sans-serif",

      color: "#86909C",
      fontWeight: "400",
    },
    maintitle: {
      fontFamily: "Inter, sans-serif",
      color: "#000",
      textTransform: "uppercase",
      textAlign: "center",
      fontWeight: "400",
      display: "inline-block",
      width: "100%",
    },
    errorText: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "400",
      color: "red",
      fontSize: "1rem",
      Margin: "1rem",
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
  },
});

export default muiTheme;
