import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  ListItem,
  Typography,
} from "@mui/material";
import prod1 from "../assets/images/prod1.webp";
import prod2 from "../assets/images/prod2.webp";
import BasicModal from "../components/Modal";
import formFactor from "../assets/logo/icons/FormFactor.webp";
import chemistry from "../assets/logo/icons/Chemistry.webp";
import nominalCapacity from "../assets/logo/icons/NominalCapacity.webp";
import cellEnergy from "../assets/logo/icons/CellEnergyDensity.webp";
import cycleLife from "../assets/logo/icons/CycleLife.webp";
import nominalVoltage from "../assets/logo/icons/NominalVoltage.webp";
import operatingVoltage from "../assets/logo/icons/OpeningVoltageRange.webp";
import maxCharge from "../assets/logo/icons/MaxCharge&Discharge.webp";
import operatingTemp from "../assets/logo/icons/OperationTemperature.webp";

const Products = [
  {
    id: 1,
    prd_name: "4.1Ah 18650​​​",
    prd_desc: [
      {
        id: 1,
        iconLabel: formFactor,
        label: "Form Factor",
        value: "18650",
      },

      {
        id: 2,
        iconLabel: chemistry,
        label: "Chemistry",
        value: "Silicon-Graphite/NMC",
      },
      {
        id: 3,
        iconLabel: nominalCapacity,
        label: "Nominal Capacity",
        value: "4.1Ah(+17% vs Best in class)",
      },
      {
        id: 4,
        iconLabel: cellEnergy,
        label: "Cell Energy Density",
        value: ">300 Wh/kg",
      },
      {
        id: 5,
        iconLabel: cycleLife,
        label: "Cycle Life",
        value: ">800 Cycles",
      },
      {
        id: 6,
        iconLabel: nominalVoltage,
        label: "Nominal Voltage",
        value: "3.45 V",
      },
      {
        id: 7,
        iconLabel: operatingVoltage,
        label: "Operating Voltage Range",
        value: "4.2 - 2.5 V",
      },
      {
        id: 8,
        iconLabel: maxCharge,
        label: "Max Charge & Discharge",
        value: "3C (0-80% Charge in < 20min)",
      },
      {
        id: 9,
        iconLabel: operatingTemp,
        label: "Operating Temperature",
        value: [
          "Charge: 0°C - 45°C",
          <br />,
          "Discharge: -40°C - 60°C (Extreme Low Temp)",
        ],
      },
    ],
    prd_img: prod1,
  },
  {
    id: 2,
    prd_name: "5.8Ah ​21700​",
    prd_desc: [
      {
        id: 1,
        iconLabel: formFactor,
        label: "Form Factor",
        value: "21700",
      },

      {
        id: 2,
        iconLabel: chemistry,
        label: "Chemistry",
        value: "Silicon-Graphite/NMC",
      },
      {
        id: 3,
        iconLabel: nominalCapacity,
        label: "Nominal Capacity",
        value: "5.8Ah(+16% vs Best in class)",
      },
      {
        id: 4,
        iconLabel: cellEnergy,
        label: "Cell Energy Density",
        value: ">300 Wh/kg",
      },
      {
        id: 5,
        iconLabel: cycleLife,
        label: "Cycle Life",
        value: ">800 Cycles",
      },
      {
        id: 6,
        iconLabel: nominalVoltage,
        label: "Nominal Voltage",
        value: "3.45 V",
      },
      {
        id: 7,
        iconLabel: operatingVoltage,
        label: "Operating Voltage Range",
        value: "4.2 - 2.5 V",
      },
      {
        id: 8,
        iconLabel: maxCharge,
        label: "Max Charge & Discharge",
        value: "3C (0-80% Charge in < 20min)",
      },
      {
        id: 9,
        iconLabel: operatingTemp,
        label: "Operating Temperature",
        value: [
          "Charge: 0°C - 45°C",
          <br />,
          "Discharge: -40°C - 60°C (Extreme Low Temp)",
        ],
      },
    ],
    prd_img: prod2,
  },
  // {
  //   id: 3,
  //   prd_name: "LITHIUM MANGANESE IRON PHOSHPATE (LMFP) AND SODIUM-ION CELLS",
  //   prd_desc: [],
  //   // prd_img: "https://placehold.co/555x391",
  // },
];

const OurProducts = () => {
  const [open, setOpen] = React.useState(false);
  const [modelData, setModelData] = React.useState([]);
  const prdmodal = (data) => {
    setModelData(data);
    setOpen(true);
  };

  return (
    <Box
      id="products"
      my={2}
      sx={{
        m: { lg: "0rem 3rem", xs: "0rem .5rem", md: "1rem" },
        p: {
          lg: "7.2rem 3rem 0rem 3rem",
          xs: "7.8rem .5rem 0rem .5rem",
          md: "1rem",
        },
      }}
    >
      <Typography
        variant={"maintitle"}
        sx={{
          fontSize: { xs: "2rem", sm: "2rem", md: "2.2rem" },
          marginBottom: { xs: "3rem", sm: "2rem", md: "3.5rem" },
        }}
      >
        Products
      </Typography>
      <Grid container spacing={4}>
        {Products.map((product) => (
          <Grid item xs={12} sm={4} md={4}>
            <Card
              sx={{
                borderRadius: "20px",
                background: "#fff",
                boxShadow: "0px 4px 41px 0px rgba(0, 0, 0, 0.07);",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                paddingTop: "18px",
              }}
            >
              {product?.prd_img && (
                <CardMedia
                  sx={{
                    height: 155,
                    borderRadius: "20px",
                    backgroundPosition: "50% 50%",
                    backgroundSize: "contain",
                  }}
                  image={product.prd_img}
                  title="prdimg1"
                />
              )}
              <CardContent
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  gutterBottom
                  variant={"textblack"}
                  component="div"
                  sx={{ fontSize: "1.45rem", textAlign: "center" }}
                >
                  {product.prd_name}
                </Typography>
              </CardContent>
              <CardActions sx={{ padding: "0 16px 16px 16px" }}>
                <Button
                  size="large"
                  onClick={() => prdmodal(product)}
                  sx={{
                    fontFamily: "Inter Sans,sans-serif",
                    fontSize: "1.25rem",
                    color: "#fff",
                    textAlign: "center",
                    backgroundColor: "#529077",
                    textTransform: "capitalize",
                    borderRadius: "13px",
                    width: "100%",
                    "&:hover": { backgroundColor: "#529070" },
                  }}
                >
                  Spec
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12} sm={4} md={4}>
          <Card
            sx={{
              borderRadius: "20px",
              background: "#fff",
              boxShadow: "0px 4px 41px 0px rgba(0, 0, 0, 0.07);",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              paddingTop: "18px",
            }}
          >
            {/* <CardMedia
                sx={{
                  height: 150,
                  borderRadius: "20px",
                  backgroundPosition: "50% 50%",
                  backgroundSize: "contain",
                }}
                image={product.prd_img}
                title="prdimg1"
              /> */}

            <CardContent
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                gutterBottom
                variant={"textblack"}
                component="div"
                sx={{ fontSize: "1.45rem", textAlign: "center" }}
              >
                LITHIUM MANGANESE IRON PHOSPHATE (LMFP) AND SODIUM-ION CELLS
              </Typography>
            </CardContent>
            <CardActions sx={{ padding: "16px" }}>
              <Button
                size="large"
                // onClick={() => prdmodal(product)}
                sx={{
                  fontFamily: "Inter Sans,sans-serif",
                  fontSize: "1.25rem",
                  color: "#000",
                  textAlign: "center",
                  backgroundColor: "#F4E7D7",
                  textTransform: "capitalize",
                  borderRadius: "13px",
                  width: "100%",
                  "&:hover": { backgroundColor: "#F4E7D7" },
                }}
              >
                Coming Soon!
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <BasicModal isOpen={open} setOpen={setOpen} modelData={modelData} />
    </Box>
  );
};

export default OurProducts;
