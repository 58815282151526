import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import inventus from "../assets/logo/partners/iventus.webp";
import nasa from "../assets/logo/partners/nasa.webp";
import dodusa from "../assets/logo/partners/dod.webp";
import car from "../assets/logo/partners/car.webp";
import ambayarea from "../assets/logo/partners/ambayarea.webp";
// import usarmy from "../assets/logo/partners/usarmy.png";
import socec from "../assets/logo/partners/socec.webp";
import nextgen from "../assets/logo/partners/Nextgen.webp";

const partners = [
  {
    id: "1",
    img: inventus,
    alt: "inventus",
    width: "65%",
    height: "auto",
  },
  {
    id: "2",
    img: nasa,
    alt: "nasa",
    width: "50%",
    height: "auto",
  },
  {
    id: "3",
    img: dodusa,
    alt: "dodusa",
    width: "38%",
    height: "auto",
  },
  {
    id: "4",
    img: car,
    alt: "car",
    width: "40%",
    height: "auto",
  },
  {
    id: "5",
    img: nextgen,
    alt: "greenfrontiercapital",
    width: "65%",
    height: "auto",
  },
  {
    id: "6",
    img: socec,
    alt: "socec",
    width: "50%",
    height: "auto",
  },
  {
    id: "7",
    img: ambayarea,
    alt: "ambayarea",
    width: "80%",
    height: "auto",
  },
  // {
  //   id: "8",
  //   img: usarmy,
  //   alt: "usarmy",
  //   width: "30%",
  //   height: "75%",
  // },
];

const Partners = () => {
  return (
    <Box
      id="partners"
      sx={{
        m: { lg: "0rem 3rem", xs: "0rem .5rem", md: "1rem" },
        p: {
          lg: "7.2rem 3rem 0rem 3rem",
          xs: "7.8rem .5rem 0rem .5rem",
          md: "1rem",
        },
      }}
    >
      <Typography
        variant={"maintitle"}
        // sx={{ marginBottom: "1.5rem" }}
        sx={{
          fontSize: { xs: "2rem", sm: "2rem", md: "2.2rem" },
          marginBottom: { xs: "3rem", sm: "2rem", md: "3.5rem" },
        }}
      >
        Partners
      </Typography>
      <Typography
        variant={"description"}
        sx={{
          display: "block",
          textAlign: "center",
          marginBottom: "2rem",
          fontSize: { xs: "0.95rem", sm: "1rem", md: "1.25rem" },
        }}
      >
        We are proud to collaborate with a diverse group of customers and
        ecosystem partners. <br />
        Below are just a few of our valued collaborators
      </Typography>
      <Grid container justifyContent={"center"}>
        {partners.map((partner) => (
          <Grid
            item
            xs={4}
            sm={4}
            md={3}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box
              sx={{
                width: partner.width,
                height: partner.height,
              }}
            >
              <img
                src={partner.img}
                alt={partner.alt}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>

      <Typography
        variant={"description"}
        sx={{
          display: "block",
          textAlign: "center",
          fontSize: { xs: "0.95rem", sm: "1rem", md: "1.25rem" },
        }}
      >
        Thank you for being part of our journey and success.
      </Typography>
    </Box>
  );
};

export default Partners;
