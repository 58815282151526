import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import keyimg from "../assets/images/Rectangle301.png";
import diff1 from "../assets/images/diff1.webp";
import diff2 from "../assets/images/diff2.webp";
import diff3 from "../assets/images/diff3.webp";
import diff4 from "../assets/images/diff4.webp";

const keydiffs = [
  {
    id: "1",
    title: "Highest Capacity and Energy Density",
    desc: "4.1Ah 18650/5.8Ah 21700, >300 Wh/Kg",
    img: diff1,
    pointer: [
      "4.1Ah 18650 and 5.8Ah 21700",
      "> 300 Wh/Kg and > 800 Wh/L",
      "3C Discharge",
    ],
  },
  {
    id: "2",
    title: "Extended Cycle Life with No Compromise",
    desc: "> 800 Cycles",
    img: diff2,
    pointer: ["> 800 Cycles"],
  },
  {
    id: "3",
    title: "Unprecedented Fast Charging for High-Energy Cylindrical Cells",
    desc: "3C Charging",
    img: diff3,
    pointer: [
      "0-80% Charge in < 20 minutes",
      "2C Consecutive Fast Charging with > 600 Cycles",
    ],
  },
  {
    id: "4",
    title: "Unmatched Low-Temperature Performance",
    desc: "-40°C to 60°C Performance",
    img: diff4,
    pointer: ["-40ºC Discharge"],
  },
  // {
  //   id: "5",
  //   title: "Extreme Low Temperature Performance",
  //   desc: "-40°C to 60°C Performance",
  //   img: "https://placehold.co/80x80",
  // },
];

const KeyDifferentiators = () => {
  return (
    <Box
      id="keydifferentiators"
      my={2}
      sx={{
        m: { lg: "0rem 3rem", xs: "0rem .5rem", md: "1rem" },
        p: {
          lg: "7.2rem 3rem 0rem 3rem",
          xs: "7.8rem .5rem 0rem .5rem",
          md: "1rem",
        },
      }}
    >
      <Typography
        variant={"maintitle"}
        sx={{
          fontSize: { xs: "2rem", sm: "2rem", md: "2.2rem" },
          marginBottom: { xs: "3rem", sm: "2rem", md: "3.5rem" },
        }}
      >
        Key Differentiators
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={5.5} md={4.5}>
          <Box
            // sx={{
            //   position: "absolute",
            //   left: 0,
            //   top: 0,
            //   width: {xs:"50%",md:"100%}",
            //   height: "100%",
            // }}
            sx={{ height: { xs: "26rem", sm: "35rem", md: "39rem" } }}
          >
            <img
              src={keyimg}
              style={{
                borderRadius: "36px",
                backgroundPosition: "50% 50%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                height: "100%",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6.5}
          md={7.5}
          sx={{ padding: { xs: "0rem", md: "0rem  2.5rem" } }}
        >
          {keydiffs.map((keydata) => (
            <Stack
              // gap={4}
              sx={{
                margin: "1.5rem 0rem",
                flexDirection: "row",
                alignItems: { xs: "flex-start", md: "center" },
                background: "#fff",
                borderRadius: "20px",
                boxShadow: "0px 4px 41px 0px rgba(0, 0, 0, 0.07)",
                padding: "1rem",
                gap: { xs: "0.8rem", md: "2rem" },
              }}
            >
              <Box
                sx={{
                  border: "3px solid #529077",
                  borderRadius: "15px",
                  display: "flex",
                  width: { xs: "6rem", sm: "5rem", md: "6rem" },
                  height: { xs: "4.5rem", sm: "4rem", md: "5.5rem" },
                  padding: { xs: ".5rem", md: "1rem" },
                }}
              >
                <img
                  src={keydata.img}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <Typography
                  variant={"textblack"}
                  sx={{
                    fontSize: {
                      xs: "1rem",
                      sm: "0.95rem",
                      md: "1.15rem",
                      lg: "1.15rem",
                      xl: "1.35rem",
                    },
                  }}
                >
                  {keydata.title}
                </Typography>
                <Typography variant={"description"}>
                  {keydata?.pointer?.map((keydataarray) => (
                    <Box
                      sx={{
                        margin: 0,
                        paddingLeft: { xs: "1.2rem", md: "1.7rem" },
                        fontSize: { xs: "0.9rem", md: "1rem" },
                      }}
                    >
                      <ul style={{ padding: 0, margin: 0 }}>
                        <li style={{}}>{keydataarray}</li>
                      </ul>
                    </Box>
                  ))}
                </Typography>
              </Box>
            </Stack>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default KeyDifferentiators;
