import { FormGroup, Typography } from "@mui/material";
import React from "react";

const TextArea = ({ formik, name, label, ...props }) => {
  return (
    <FormGroup sx={{ marginBottom: "2.3rem" }}>
      <Typography
        variant="body1"
        sx={{ color: "#465152", marginBottom: "4px", fontSize: "1rem" }}
      >
        {label}
      </Typography>
      <textarea
        className="text-area"
        value={props.value}
        {...props}
        style={{
          height: "10rem",
          borderRadius: "18px",
          padding: "1rem",
          fontSize: "0.93rem",
          border: "0.9px solid #DFDFDF",
          fontFamily: "Inter,Sans-serif",
        }}
      />
      <Typography variant="errorText">
        {formik?.errors[name] && formik?.touched[name] && formik?.errors[name]}
      </Typography>
    </FormGroup>
  );
};

export default TextArea;
