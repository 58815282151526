import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "../assets/logo/black2.png";
import "../App.css";
import { Link } from "@mui/material";

const drawerWidth = 240;
const navItems = [
  {
    id: 1,
    name: "About Us",
    path: "#aboutus",
  },
  {
    id: 2,
    name: "Products",
    path: "#products",
  },
  {
    id: 3,
    name: "Partners",
    path: "#partners",
  },
  {
    id: 4,
    name: "Team",
    path: "#teams",
  },
  {
    id: 5,
    name: "Newsroom",
    path: "#newsroom",
  },
  {
    id: 6,
    name: "Contact Us",
    path: "#contactus",
  },
];

function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{}}>
      <Typography variant="h6" sx={{ backgroundColor: "#FFE6C9", p: "1rem" }}>
        Menu<span style={{ paddingLeft: "8rem" }}>x</span>
      </Typography>

      <Divider />
      <List>
        {navItems.map((item) => (
          <a style={{ textDecoration: "none" }} href={item.path} key={item.id}>
            <Typography
              sx={{
                fontFamily: "Inter,Sans Serif",
                fontSize: "1.25rem",
                p: "1rem",
              }}
            >
              {item.name}
            </Typography>
          </a>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", height: "5rem" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          background: "#fff",
          height: "5rem",

          padding: { xs: "0 0rem", sm: "0 0rem", md: "0 5rem" },
        }}
      >
        <Toolbar
          sx={{
            height: "14vh",
            minHeight: "14vh",
            justifyContent: "space-between",
          }}
        >
          <a href="/">
            <Box
              width={{ xs: "10rem", sm: "11rem", md: "12rem" }}
              // height={{ xs: "2rem", md: "auto" }}
              sx={{ flexGrow: 1, display: { xs: "block", lg: "block" } }}
            >
              <img src={logo} width={"100%"} height={"100%"} />
            </Box>
          </a>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ mr: 3, display: { lg: "none" } }}
          >
            <MenuIcon sx={{ color: "#000" }} />
          </IconButton>
          <Box sx={{ display: { xs: "none", lg: "flex" }, gap: "30px" }}>
            {navItems.map((item) => (
              <a
                href={item.path}
                key={item.id}
                style={{ textDecoration: "none", textTransform: "uppercase" }}
              >
                <Typography sx={{ fontSize: "1rem" }}>{item?.name}</Typography>
              </a>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", lg: "none", xl: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navbar;
